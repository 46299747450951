import React, { Component, lazy, Suspense } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { EntitlementProvider } from "./context/EntitlementContext";
import client from "apollo/client";
import PrivateRoute from "components/utils/PrivateRoute";
import { muiTheme, muiThemeV5 } from "styles";
import CONSTANTS from "./constants";
import { ThemeProvider } from "@mui/material/styles";
import "./assets/fonts/Gilroy/Gilroy-Regular.otf";
import "./assets/fonts/Google_Sans/GoogleSans.ttf";
import "styles/main.css";
import * as Sentry from "@sentry/browser";
import CheckSessionTimeoutPoller from "./components/utils/CheckSessionTimeoutPoller";
import AcceptInvitation from "./components/pages/AcceptInvitation";

const { AppTypes, UserTypes } = CONSTANTS;

const PublicRouter = lazy(() => import("components/routers/PublicRouter")),
  SessionRouter = lazy(() => import("components/routers/SessionRouter")),
  AgencyRouter = lazy(() => import("components/routers/AgencyRouter")),
  DiscoverDemoRouter = lazy(() =>
    import("components/routers/DiscoverDemoRouter")
  ),
  CreateAccount = lazy(() => import("components/pages/CreateAccount")),
  EnterpriseRouter = lazy(() => import("components/routers/EnterpriseRouter"));

class RouterComponent extends Component {
  render() {
    const { currentUser } = this.props;
    let appStore = {};
    let AppRouter;

    const urlParams = new URLSearchParams(window.location.search);
    const selectedOrgId = urlParams.get("selectedOrgId");

    if (selectedOrgId) {
      sessionStorage.setItem('selectedOrgId', selectedOrgId);
    }

    switch (currentUser.type) {
      case UserTypes.AGENCY_ADMIN:
      case UserTypes.AM:
        AppRouter = AgencyRouter;
        appStore.appType = AppTypes.AGENCY;
        break;
      case UserTypes.ENTERPRISE_USER:
        if (!sessionStorage.getItem('selectedOrgId')) {
          AppRouter = EnterpriseRouter;
          appStore.appType = AppTypes.ENTERPRISE;
        } else {
          AppRouter = AgencyRouter;
          appStore.appType = AppTypes.AGENCY;
        }
        break;
      default:
        break;
    }

    if (window.location.pathname.includes("dd/")) {
      AppRouter = DiscoverDemoRouter;
    }
    return <AppRouter {...this.props} />;
  }
}
class App extends Component {
  state = {
    email: undefined,
    loggedIn: false,
  };
  render() {
    return (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <ThemeProvider theme={muiThemeV5}>
            <MuiThemeProvider theme={muiTheme}>
              <Suspense fallback={<div />}>
                <Router>
                  <CheckSessionTimeoutPoller />
                  <Switch>
                    <Route path="/public" render={() => <PublicRouter />} />
                    <Route path="/session" render={() => <SessionRouter />} />
                    <Route
                      path="/create-account"
                      render={() => <CreateAccount />}
                    />
                    <PrivateRoute
                      render={({ currentUser }) => {
                        Sentry.configureScope((scope) =>
                          scope.setUser({
                            id: currentUser.id,
                            username: currentUser.name,
                            email: currentUser.email,
                          })
                        );
                        return (
                          <EntitlementProvider userId={currentUser.id}>
                            <RouterComponent currentUser={currentUser} />
                          </EntitlementProvider>
                        );
                      }}
                    />
                  </Switch>
                </Router>
              </Suspense>
            </MuiThemeProvider>
          </ThemeProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default App;
